<template>
  <div class="box">
    <div class="tops">
      {{ Detail.info }}
      <span></span>
    </div>

    <!-- 切换tab -->
    <div class="time">
      {{ Detail.updated_at }}
      <span>Yoho</span>
    </div>

    <div class="mi" v-html="Detail.introduce"></div>
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";
export default {
  data() {
    return {
      // 新的
      open: null,
      Detail: {},
      active: 0, // tab
      strings: null, // 简介
    };
  },
  // components: { noData },
  created() {
    // http://localhost:8080/about?uid=5&kid=1
    const { uid, kid } = this.$route.query;
    this.postDetail({ uid, id: kid, page: 1 });

    this.$nextTick(() => {
      this.top = this.$refs.magtop.$el.getBoundingClientRect().top + 24;
    });
  },

  components: {
    // eslint-disable-next-line vue/no-unused-components

    [Toast.name]: Toast, // 文字提示
    [Dialog.name]: Dialog,
  },
  methods: {
    // 播放回调
    onPlayerPlay() {
      const { uid, kid } = this.$route.query;
      // 推一个播放量
      this.$request(2, { id: uid, kid }).then(() => {});
    },

    // 获取信息
    postDetail(e) {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      this.$request(4, e)
        .then((res) => {
          this.Detail = res;
        })
        .catch((message) => {
          Dialog.alert({
            message,
            confirmButtonColor: "#1989fa",
          }).then(() => {});
        })
        .finally(() => {
          Toast.clear();
        });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .vjs-big-play-button {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.box {
  padding: 30px;
  .tops {
    font-size: 40px;
    line-height: 50px;
    span {
      display: block;
      margin: 11px 0;
      width: 160px;
      height: 2px;
      background: #2ecc71;
    }
  }
  .time {
    color: #7f8c8d;
    font-size: 26px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ecf0f1;
    span {
      display: inline-block;
      margin-left: 30px;
      line-height: 50px;
    }
  }

  .mi {
    font-size: 32px;
    line-height: 50px;
    ::v-deep p{
      margin-bottom: 10px;
    }
    ::v-deep img{
      margin-bottom: 10px;
      max-width: 100%;
    }
  }
}
</style>
